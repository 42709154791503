import axios from 'axios'
import { ElLoading, ElMessage } from 'element-plus'
import store from '@/store'
import router from '@/router'

const $axios = axios.create({
  timeout: 30000,
  baseURL: '/api',
})

let loading = null

$axios.interceptors.request.use(
  (config) => {
    loading = ElLoading.service({ text: '拼命加载中' })
    const token = 'Bearer ' + store.getters.token
    if (token) {
      config.headers.Authorization = token
    }
    return config
  },
  (error) => {
    console.log('error' + error)
    return Promise.reject(error)
  }
)

$axios.interceptors.response.use(
  (response) => {
    if (loading) {
      loading.close()
    }
    const code = response.status
    if ((code >= 200 && code < 300) || code === 304) {
      return Promise.resolve(response.data)
    } else {
      return Promise.reject(response)
    }
  },
  (error) => {
    if (loading) {
      loading.close()
    }
    console.log(error)
    if (error.response) {
      switch (error.response.status) {
        case 401:
          ElMessage.error(error.response.data.message)
          // 返回401 清除token信息并跳转到登陆页面
          store.commit('DEL_TOKEN')
          router.replace({
            path: '/login',
            query: {
              redirect: router.currentRoute.fullPath,
            },
          })
          break
        case 403:
          ElMessage.error('您没有权限访问该页面')
          router.replace({
            path: '/login',
          })
          break
        case 404:
          ElMessage.error('网络请求不存在')
          router.replace({
            path: '/404',
            query: {
              redirect: router.currentRoute.fullPath,
            },
          })
          break
        default:
          ElMessage.error(error.response.data.message)
      }
    } else {
      // 请求超时或者网络有问题
      if (error.message.includes('timeout')) {
        ElMessage.error('请求超时！请检查网络是否正常')
      } else {
        ElMessage.error('请求失败，请检查网络是否已连接')
      }
    }
    return Promise.reject(error)
  }
)

// get，post请求方法
export default {
  post(url, data) {
    return $axios({
      method: 'post',
      url,
      // data: JSON.stringify(data),
      data: data,
      // headers: {
      //   // 'Content-Type': 'application/json'
      // }
    })
  },
  get(url, params) {
    // const accessToken = store.getters.token
    return $axios({
      method: 'get',
      url,
      params,
      headers: {
        // Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    })
  },
  put(url, data) {
    return $axios({
      method: 'put',
      url,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
}
