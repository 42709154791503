import { login, getInfo, signUp } from '@/api/login'
import router from '@/router'

const state = {
  token: localStorage.getItem('token') ? localStorage.getItem('token') : '', // 认证凭证'
  cus_name: '',
  roles: [],
  acc_num: '',
  cus_phone: '',
  cus_email: '',
  cus_balance: 0,
  role_id: 0,
  resetps_token: '',
}
const mutations = {
  SET_TOKEN(state, val) {
    state.token = val
    localStorage.setItem('token', val)
    console.log("Current State: ", state);
  },
  SET_PS_TOKEN(state, payload) {
    state.resetps_token = payload
    localStorage.setItem('resetps_token', payload)
  },
  DEL_TOKEN(state) {
    state.token = ''
    state.acc_num = ''
    state.roles = ''
    state.introduce = ''
    state.cus_name = ''
    state.cus_phone = ''
    state.cus_email = ''
    state.cus_balance = ''
    localStorage.removeItem('token')
  },
  SET_ROLES(state, payload) {
    state.roles = payload
  },
  SET_ROLE_ID(state, payload) {
    state.role_id = payload
  },
  SET_ACC(state, payload) {
    state.acc_num = payload
  },
  SET_NAME(state, payload) {
    state.cus_name = payload
  },
  SET_PHONE(state, payload) {
    state.cus_phone = payload
  },
  SET_EMAIL(state, payload) {
    state.cus_email = payload
  },
  SET_BALANCE(state, payload) {
    state.cus_balance = payload
  },
  SET_INTRODUCE(state, payload) {
    state.introduce = payload
  },
}
const actions = {
  // user login
  _login({ commit }, formdatas) {
    return new Promise((resolve, reject) => {
      login(formdatas)
        .then((res) => {
          if (res.code === 0) {
            if (res.success) {
              commit('SET_TOKEN', res.data.token)
              commit('SET_ACC', res.data.acc_num)
              localStorage.setItem('acc_num', res.data.acc_num)
            } else {
              console.log(res)
            }
            resolve(res)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  loginOut({ commit }) {
    commit('DEL_TOKEN')
    router.push({
      path: '/login',
      query: {
        redirect: '/home',
      },
    })
  },
  _getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getInfo()
        .then((res) => {
          if (res.code === 0) {
            commit('SET_ROLES', res.data.roles)
            commit('SET_ACC', res.data.acc_num)
            commit('SET_NAME', res.data.first_name + ' ' + res.data.last_name)
            commit('SET_PHONE', res.data.phone_num)
            commit('SET_EMAIL', res.data.email_addr)
            commit('SET_BALANCE', res.data.balance)
            commit('SET_ROLE_ID', res.data.role_id)
          }
          resolve(res.data)
        })
        .catch((error) => {
          if (error.response.status === 401) {
            router.push({
              path: '/login',
            })
          }
          reject(error)
        })
    })
  },
  _signup({ commit }, formdatas) {
    return new Promise((resolve, reject) => {
      signUp(formdatas)
        .then((res) => {
          console.log(res)
          if (res.code === 0) {
            if (res.success) {
              commit('SET_TOKEN', res.data.token)
              commit('SET_ACC', res.data.acc_num)
              localStorage.setItem('acc_num', res.data.acc_num)
              commit('SET_ROLE_ID', res.data.role_id)
            } else {
              console.log(res)
            }
            resolve(res)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
