const state = {
  websocket: null,
  websocketData: null,
  websocketLoading: false,
  connectionStatus: false,
}

const mutations = {
  SET_WEBSOCKET(state, websocket) {
    state.websocket = websocket
  },
  SET_WEBSOCKET_DATA(state, data) {
    state.websocketData = data
  },
  SET_WEBSOCKET_LOADING(state, isLoading) {
    state.websocketLoading = isLoading
  },
  SET_CONNECTION_STATUS(state, status) {
    state.connectionStatus = status
  },
}

const actions = {
  connectWebSocket({ commit, dispatch, state }) {
    if (state.websocket) {
      return; // 已经有一个WebSocket连接，不需要重新连接
    }

    const websocket = new WebSocket('wss://www.box-shipper.com/ws/connection');
    const messageCallbacks = new Map(); // 移动到这里定义

    let pingInterval = null;
    const pingIntervalDuration = 30000;
    let lastPongTimestamp = null;

    function sendPing() {
      if (websocket.readyState === WebSocket.OPEN) {
        websocket.send(JSON.stringify({ data: 'ping' }));
      }
    }

    websocket.onopen = () => {
      commit('SET_WEBSOCKET', websocket);
      commit('SET_CONNECTION_STATUS', true);
      commit('SET_MESSAGE_CALLBACKS', messageCallbacks); // 添加这一行
      pingInterval = setInterval(sendPing, pingIntervalDuration);
    };

    websocket.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);

        if (data.data === 'pong') {
          lastPongTimestamp = Date.now();
        } else if (messageCallbacks.has(data.data)) {
          const callback = messageCallbacks.get(data.data);
          callback(data.data);
        } else {
          commit('SET_WEBSOCKET_DATA', data);
        }
      } catch (error) {
        console.error('Failed to parse JSON message:', error);
      }
    };

    websocket.onclose = () => {
      commit('SET_WEBSOCKET', null);
      commit('SET_CONNECTION_STATUS', false);
      clearInterval(pingInterval);
      // 重新连接
      setTimeout(() => {
        dispatch('connectWebSocket');
      }, 5000);
    };

    const checkConnectionStatus = () => {
      const currentTime = Date.now();
      const timeoutDuration = 2 * pingIntervalDuration;
      
      if (lastPongTimestamp && currentTime - lastPongTimestamp > timeoutDuration) {
        websocket.close();
      }
    };

    setInterval(checkConnectionStatus, 1000);

    return messageCallbacks; // 返回messageCallbacks
  },
  async sendWebSocketMessage({ commit, state, getters }, message) {
    const websocket = state.websocket;
    const messageCallbacks = state.messageCallbacks;
    const token = getters.token;  // 使用getters来访问token
    
    return new Promise((resolve, reject) => {
      if (websocket && websocket.readyState === WebSocket.OPEN) {
        commit('SET_WEBSOCKET_LOADING', true);
  
        const action = message.action; // 假设消息类型存储在 action 字段
        const payload = message.data;
  
        try {
          // 在这里将 token 添加到要发送的消息中
          websocket.send(JSON.stringify({ action, payload, token }));
          resolve();
        } catch (error) {
          reject(error);
          commit('SET_WEBSOCKET_LOADING', false);
          messageCallbacks.delete(action);
        }
      } else {
        reject(new Error("WebSocket is not open"));
        commit('SET_WEBSOCKET_LOADING', false);
      }
    });
  }
  
}

export default {
  state,
  mutations,
  actions,
}
