<template>
  <div class="notificatBar">
    <div class="cardBox">
      <div class="cardHead">
        <p>消息中心</p>
        <el-icon @click="$store.commit('app/SET_MSGISOPEN')"><Close /></el-icon>
      </div>
      <ul class="conUl">
        <li v-for="item in msgList" :key="item.id">
          <div class="conUl_sp">
            <span class="conUl_sp0">{{ item.content }}</span>
            <span class="conUl_sp1">{{ item.time }}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      message: '',
      eventSource: null,
      msgList: [
        {
          id: '1',
          content: '测试消息',
          link: '',
          time: '2022-12-22 12:12:12',
        },
      ],
    }
  },
  mounted() {
    // 创建一个EventSource对象连接到服务器的SSE路由
    this.eventSource = new EventSource(
      '/api/events/' + this.$store.getters.token
    )

    // 监听message事件，当接收到服务器推送的消息时触发
    this.eventSource.onmessage = (event) => {
      // console.log(event);
      if (event.data !== 'Up to date') {
        const res = JSON.parse(`${event.data}`)
        this.title = res.title
        this.message = res.message
        this.msgList.unshift({
          id: (this.msgList.length + 1).toString(),
          content: this.title,
          message: this.message,
          time: new Date().toLocaleString(),
        })
        if (this.msgList.length > 10) {
          this.msgList.pop()
        }
        this.$store.commit('notification/SET_HAS_NEW_MESSAGE', true);
      }
    }

    // 监听error事件，当出现连接错误时触发
    this.eventSource.onerror = (error) => {
      console.error('Error occurred:', error)
      this.eventSource.close() // 关闭连接
    }
  },
  beforeUnmount() {
    // 在组件销毁前关闭SSE连接
    if (this.eventSource) {
      this.eventSource.close()
    }
  },
}
</script>

<style lang="scss" scoped>
.cardBox {
  width: 100%;

  .cardHead {
    height: 50px;
    padding: 0 20px;
    border-bottom: 1px solid #e4e4e4;
    line-height: 50px;

    p {
      font-size: 16px;
      color: #333;
      float: left;
    }

    i {
      float: right;
      font-size: 20px;
      margin-top: 14px;
      color: #b3b3b3;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        color: #333;
      }
    }
  }
}

.conUl {
  li {
    height: 50px;
    line-height: 50px;

    .conUl_link {
      display: block;
      margin: 0 20px;
      height: 100%;
      border-bottom: 1px solid #e4e4e4;
    }

    .conUl_sp0 {
      font-size: 14px;
    }

    .conUl_sp1 {
      font-size: 12px;
      color: #b3b3b3;
      float: right;
    }
  }
}
</style>
