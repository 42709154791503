<template>
  <div class="header">
    <div class="header_l">
      <a href="javascript:;" class="logoLink"
        ><img
          :src="require('@/assets/logo/Box-blue.png')"
          alt="logo"
        />Box Shipper Client</a
      >
    </div>
    <div class="header_r">
      <div class="headr_d1">
        <side-collapse class="sidecoll"></side-collapse>
        <bread-crumb class="bread"></bread-crumb>
      </div>
      <div class="headr_d2">
        <ul class="headrUl clearFixed">
          <li id="domMessage">
    <el-badge :is-dot="hasNewMessage" @click="toggleMsgShow">
      <el-icon><Message /></el-icon> 
    </el-badge>
  </li>
          <li id="domFullScreen">
            <full-screen></full-screen>
          </li>
          <li id="domPersonal">
            <user-dropdown></user-dropdown>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import SideCollapse from '@/components/SideCollapse'
import BreadCrumb from '@/components/BreadCrumb'
import UserDropdown from '@/components/UserDropdown'
import FullScreen from '@/components/FullScreen'
import { mapGetters } from 'vuex'
export default {
  components: {
    SideCollapse,
    BreadCrumb,
    UserDropdown,
    FullScreen
  },
  computed: {
    ...mapGetters(['hasNewMessage']),
  },
  methods: {
    toggleMsgShow() {
      this.$store.commit('notification/SET_HAS_NEW_MESSAGE', false)
      this.$store.commit('app/SET_MSGISOPEN')
    },
  },
}
</script>
