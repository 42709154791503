<template>
  <div class="sideBar" id="domSideBar">
    <el-scrollbar>
      <el-menu :default-active="activeMenu" class="el-menu-vertical-demo" background-color="#3a3f51"
        text-color="#b5b6bd" active-text-color="rgb(79, 148, 212)" mode="vertical" :collapse-transition="false"
        :collapse="opened">
        <sidebar-item v-for="item in routes" :key="item.path" :item="item" :basePath="item.path"></sidebar-item>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import SidebarItem from './SideBarItem'
import { mapGetters } from 'vuex'
export default {
  components: { SidebarItem },
  computed: {
    ...mapGetters(['routes', 'opened']),
    activeMenu() {
      // console.log("Routes: ", this.routes);
      // console.log("Opened: ", this.opened);
      return this.$route.path;
    },
  },
}
</script>

