<template>
  <component :is="returnTag(to).is" :to="to" :href="to" :target="returnTag(to).target">
    <slot />
  </component>
</template>

<script>
import { isAbsolutePath } from '@/utils/validate'
export default {
  props: {
    to: {
      type: String,
      required: true
    }
  },
  methods: {
    returnTag(path) {
      if (isAbsolutePath(path)) {
        return {
          is: 'a',
          target: '_blank'
        }
      } else {
        return {
          is: 'router-link'
        }
      }
    }
  }
}
</script>
