<template>
  <div @click="toggleFull">
    <el-icon ><FullScreen /></el-icon>
  </div>
</template>

<script>
import screenfull from 'screenfull'
export default {
  methods: {
    toggleFull() {
      if (screenfull.isEnabled) {
        this.$message({
          type: 'warning',
          message: 'you browser can not work',
        })
        return false
      }
      screenfull.request();
    },
  },
}
</script>
