import $axios from './index'

export function login(data) {
  const url = '/login'
  return $axios.post(url, data)
}
export function getInfo() {
  const url = '/account'
  return $axios.get(url)
}

export function verify_token(token) {
  const url = '/verify/' + token
  return $axios.get(url)
}

export function signUp(data) {
  const url = 'account/register'
  return $axios.post(url, data)
}

export function forget_password(data) {
  const url = '/account/forgotPassword'
  return $axios.post(url, data)
}

export function reset_password(data) {
  const url = '/resetPassword'
  return $axios.put(url, data)
}
