<template>
      <el-dropdown>
        <span class="userDrop">
          <span class="userDrop_text">
          {{ cus_name }}<el-icon><CaretBottom /></el-icon>
        </span>
        <img src="./user-01.jpeg" alt="user" />
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item>
          <router-link to="/">主页</router-link>
        </el-dropdown-item>
        <el-dropdown-item v-if="!check_user">
          <router-link to="/account">账户信息</router-link>
        </el-dropdown-item>
        <el-dropdown-item divided>
          <a @click="_loginOut()">登出</a>
        </el-dropdown-item>

          </el-dropdown-menu>
        </template>
      </el-dropdown>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['cus_name'],['role_id']),
    check_user() {
      return this.cn_internal.includes(this.role_id)
    }
  },
  data() {
    return {
      cn_internal:[6],
    }
  },
  methods: {
    _loginOut() {
      this.$store.dispatch('user/loginOut')
    }
  }
}
</script>
