<template>
  <div class="sideCollapse" id="domColapse">
    <i @click="toggleOpen()">
    <template v-if="opened">
      <!-- 导入的展开图标 -->
      <el-icon><Expand /></el-icon>
    </template>
    <template v-else>
      <!-- 导入的折叠图标 -->
      <el-icon><Fold /></el-icon>
    </template>
  </i>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['opened']),
    // toggleClass() {
    //   return {
    //     'el-icon-s-unfold': this.opened === 'true',
    //     'el-icon-s-fold': this.opened !== 'true'
    //   }
    // }
  },
  methods: {
    toggleOpen() {
      this.$store.commit('app/SET_OPENED', !this.opened)
    },
  },
}
</script>

<style lang="scss" scoped>
.sideCollapse i {
  font-size: 18px;
  color: #363f44;
  cursor: pointer;
}
</style>
