<template>
  <div class="pageMain">
    <transition name="fade-page" mode="out-in">
      <keep-alive v-if="!$route.meta.noCache">
        <router-view></router-view>
      </keep-alive>
      <router-view v-else></router-view>
    </transition>
  </div>
</template>

<script>
export default {}
</script>
