const getters = {
  token: (state) => state.user.token,
  cus_name: (state) => state.user.cus_name,
  cus_phone: (state) => state.user.cus_phone,
  cus_email: (state) => state.user.cus_email,
  cus_balance: (state) => state.user.cus_balance,
  acc_num: (state) => state.user.acc_num,
  roles: (state) => state.user.roles,
  role_id: (state) => state.user.role_id,
  introduce: (state) => state.user.introduce,
  routes: (state) => state.permission.routes,
  addRoutes: (state) => state.permission.addRoutes,
  resetps_token: (state) => state.user.resetps_token,
  hasNewMessage: (state) => state.notification.hasNewMessage,
  opened: (state) => {
    if (state.app.opened === 'false') {
      return false
    } else if (state.app.opened === 'true') {
      return true
    }
  },
  msgIsShow: (state) => state.app.msgIsShow,
  showDriver: (state) => state.app.showDriver,
}
export default getters
