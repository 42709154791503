import { createStore } from 'vuex'
import getters from './getters'
const path = require('path')

// Automatic module registration
const modulesFiles = require.context('./modules', false, /\.js$/)

const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = path.basename(modulePath, '.js')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

const store = createStore({
  modules,
  getters,
})

export default store
