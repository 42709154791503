const state = {
    hasNewMessage: false
}
const mutations = {
    SET_HAS_NEW_MESSAGE(state, payload) {
        state.hasNewMessage = payload;
    }
}

export default {
    namespaced: true,
    state,
    mutations
}