<template>
  <div class="sideItem" v-if="!item.hidden">
    <template v-if="hasOnlyChild(item.children, item) && (!childItem.children || childItem.noChild)">
      <page-link :to="resolvePath(childItem.path)">
        <el-menu-item :index="resolvePath(childItem.path)">
          <el-icon>
            <component :is="childItem.meta.icon"></component>
          </el-icon>
          <span slot="title">{{ childItem.meta.title }}</span>
        </el-menu-item>
      </page-link>
    </template>
    <el-sub-menu v-else :index="resolvePath(item.path)">
      <template #title>
        <el-icon>
          <component :is="item.meta.icon"></component>
        </el-icon>
            <span slot="title">{{ item.meta.title }}</span>
          </template>
      <sidebar-item
        v-for="child in item.children"
        :key="child.path"
        :item="child"
        :basePath="resolvePath(child.path)"
      ></sidebar-item>
    </el-sub-menu>
  </div>
</template>

<script>
import PageLink from './Link'
import { isAbsolutePath } from '@/utils/validate'
import path from 'path'
export default {
  name: 'SidebarItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    basePath: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      childItem: null,
    }
  },
  methods: {
    hasOnlyChild(children = [], item) {
        const visibleChildren = children.filter(child => !child.hidden);
        if (visibleChildren.length === 0) {
            this.childItem = { ...item, path: '', noChild: true };
            return true;
        } 
        if (visibleChildren.length === 1) {
            this.childItem = visibleChildren[0];
            return true;
        }
        // console.log('Item meta:', item.meta); 
        return false;
    },
    resolvePath(router) {
      if (isAbsolutePath(router)) {
        return router
      }
      if (isAbsolutePath(this.basePath)) {
        return this.basePath
      }
      return path.join(this.basePath, router)
    },
  },
  components: {
    PageLink,
  },
}
</script>
